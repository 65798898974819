// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery");
require("jquery-ui");
require("jquery.typewatch");
require("jquery.cookie");
require("moment");
require("tempusdominus-bootstrap-4");

const Choices = require('choices.js');
const ClipboardJS = require('clipboard');
window.ClipboardJS = ClipboardJS

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("turbolinks").start();
require("channels");
require('suggestags');

import $ from 'jquery';
global.$ = jQuery;

import '../stylesheets/application.scss';
import 'expose-loader?$!jquery';
import 'expose-loader?jQuery!jquery';
import 'jquery.cookie';
import 'bootstrap';
import 'datatables';
import "datatables.net";
import 'datatables.net-bs4';
import '../src/font-awesome.js';
import './bootstrap-combobox';
import './billing.js.coffee';
import './buy_certificates.js.coffee';
import './jquery.countdown.min';
import './datatables_service.js.coffee';
import './leadertelecom_widget_partner.js.coffee';
import './login_warnings.js.coffee';
import './notifications.js.coffee';
import './popovers.js.coffee';
import './popovers.js';
import './products_filter_servers.js.coffee';
import './products_filter_partners.js.coffee';
import './product_server_table.js';
import './user_servers.js';
import './service_info.js.erb';
import './leads.js.coffee';
import './csr_generator.js';
import './reissuance_certificate.js.coffee';
import './dcv_widget.js.coffee';
import './leadertelecom_widget.coffee';
import '../../../public/leaderssl_activation.min';
import './ssl.js';
import './copy_text.js';


$(document).on('turbolinks:load', function() {
    $("#suboption").on('change', function(){
        if($(this).val() != "0"){
            $(this).parents('form').submit();
        }
    });

    $('#contacts_table').DataTable();

    $('.certificates-list tbody tr').on('click', function(){
        window.location = $(this).find('a').attr('href');
    });

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });

    // Clipboard
    var clipboard = new ClipboardJS('.clipboard-btn');

    clipboard.on('success', function(e) {
    });

    clipboard.on('error', function(e) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
    });

    if (typeof (gon) != 'undefined') {
        if (gon.error_404_occured) {
            gtag('event', 'Error_404', {'event_category': 'Error_404'});
        }

        if (gon.error_500_fatal) {
            gtag('event', 'Error_500', {'event_category': 'Error_500', 'event_label': gon.error_500_fatal});
        }
    }

    $(function(){
        $('.dropdown').hover(
            function() {
                $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeIn(300);
            },
            function() {
                $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeOut(300);
            }
        );
    });

    var url_params = window.location.search.substring(1);
    if (url_params == "modal=show") {
        $('#reissuance').modal('show');
    }
});

$(document).on("turbolinks:click", function() {
    $(document).on("turbolinks:request-start", function() {
        $('#spinnerContainer').modal('show');
        $('.modal-backdrop').css('background', '#f5f5f5');
    });
    return $(document).on("turbolinks:request-end", function() {
        $('#spinnerContainer').modal('hide');
    });
});
